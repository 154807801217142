import ProgressBar from './progress-bar.js';

class FSProgressBar extends ProgressBar {
  constructor() {
    super();
    this.init();
  }

  init() {
    const rate = Number(Shopify.currency.rate);
    const min = Number(this.dataset.feAmount);
    if (!min || !rate) return;
    const order = Number(this.dataset.order) / 100;
    const min_by_currency = min * rate;
    if (order == undefined) return;
    if ((order / min_by_currency) * 100 > 100) {
      this.setProgressBar(100);
    } else {
      this.setProgressBar((order / min_by_currency) * 100);
    }
    this.setProgressBarTitle(order, min_by_currency);
  }

  setProgressBar(progress) {
    const p = this.querySelector('.progress');
    p.style.width = progress + '%';
    if (progress === 100) {
      this.classList.add('free-shipping');
    } else {
      this.classList.remove('free-shipping');
    }
  }

  setProgressBarTitle(order, min_by_currency) {
    let fe_unavaiable = this.dataset.feUnavaiable;
    const fe_avaiable = this.dataset.feAvaiable;
    const title = this.querySelector('.progress-bar-message .message');
    if (!title) return;
    if (order >= min_by_currency) {
      title.innerHTML = fe_avaiable;
    } else {
      const ammount = '{{ amount }}';
      fe_unavaiable = fe_unavaiable.replace(
        '{{ amount }}',
        '<strong class="heading-color">{{ amount }}</strong>'
      );
      title.innerHTML = fe_unavaiable.replace(
        ammount.trim(),
        window.Shopify.formatMoney(
          (min_by_currency - order) * 100,
          themeGlobalVariables.settings.money_format
        )
      );
    }
  }
}

export default FSProgressBar;
